import { FormControl, TextField } from '@mui/material';
import React from 'react';

const MealsServedInput = ({ mealsServed, setMealsServed }) => {
	return (
		<div>
			<FormControl fullWidth={true}>
				<TextField
					type='number'
					label='Meals Served'
					value={mealsServed}
					onChange={(e) => setMealsServed(e.target.value)}
					required
				/>
			</FormControl>
		</div>
	);
};

export default MealsServedInput; 